<template>
  <div>
    <a-popover v-model="showPopover" trigger="click" placement="bottom">
      <template slot="content">
        <div class="buttons-wrapper">
          <a-button
            @click.prevent="openAddStaffMembersModal"
            v-if="!cannotAddStaffMembers"
            icon="usergroup-add"
            >Add staff member(s)</a-button
          >

          <a-button
            v-if="!isChat"
            @click.prevent="openAddTaskListModal"
            icon="unordered-list"
            >Use a playbook</a-button
          >

          <a-button
            v-if="!isChat"
            :disabled="!canCreateAnnouncement"
            @click.prevent="openCreateAnnouncementModal"
            icon="notification"
            >Make an announcement</a-button
          >

          <a-button
            @click.prevent="openMediaUploader"
            icon="picture"
            >Send image</a-button
          >

          <a-button
            @click.prevent="openWebcamCaptureModal"
            icon="camera"
            >Send photo</a-button
          >

          <a-button
            @click.prevent="openSendHelpVideoModal"
            icon="video-camera"
            >Send help video</a-button
          >

          <a-button
            v-if="guidanceModeEnabled"
            @click.prevent="openSendGuidanceModal"
            icon="book"
            >Send guidance</a-button
          >

          <a-button
            @click.prevent="openSendLocationModal"
            icon="environment"
            >Send location</a-button
          >
        </div>
      </template>
      <a-button type="primary" shape="circle" icon="plus" size="large" />
    </a-popover>

    <add-staff-members-modal
      @add-staff-members="addStaffMembers"
      :tenant-id="tenantId"
      :org-id="orgId"
      :visible="addStaffMembersModalVisible"
      :membership="membership"
      @close-modal="closeAddStaffMembersModal"
    ></add-staff-members-modal>

    <add-task-list-modal
      @add-checklist="addChecklist"
      :tenant-id="tenantId"
      :org-id="orgId"
      :visible="addTaskListModalVisible"
      @close-modal="closeAddTaskListModal"
    ></add-task-list-modal>

    <send-help-video-modal
      :my-presence-id="myPresenceId"
      :visible="sendHelpVideoModalVisible"
      @close-modal="closeSendHelpVideoModal"
    ></send-help-video-modal>

    <upload-media
      v-if="showMediaUploader"
      :my-presence-id="myPresenceId"
      @file-uploaded="handleFileUploaded"
    ></upload-media>

    <webcam-capture-modal
      :visible="showWebcamCaptureModal"
      :my-presence-id="myPresenceId"
      @image-uploaded="handleWebcamImageUploaded"
      @close-modal="closeWebcamCaptureModal"
    ></webcam-capture-modal>

    <send-guidance-modal
      v-if="guidanceModeEnabled"
      :visible="showSendGuidanceModal"
      :tenant-id="tenantId"
      :my-presence-id="myPresenceId"
      :org-id="orgId"
      @close-modal="closeSendGuidanceModal"
    ></send-guidance-modal>

    <create-announcement-modal
      v-if="isAnnouncementModalInitialised"
      :visible="createAnnouncementModalVisible"
      @close-modal="closeCreateAnnouncementModal"
      :org-id="orgId"
      :dont-redirect="true"
      :incident-id="incidentId"
    ></create-announcement-modal>

    <send-location-modal
    v-if="sendLocationModalVisible"
    title="Send Your Location"
    :my-presence-id="myPresenceId"
    ></send-location-modal>

  </div>
</template>

<script>
import AddTaskListModal from "./SendMessageActions/AddTaskListModal.vue";
import AddStaffMembersModal from "./SendMessageActions/AddStaffMembersModal.vue";
import SendHelpVideoModal from "./SendMessageActions/SendHelpVideoModal.vue";
import UploadMedia from "./SendMessageActions/UploadMedia.vue";
import WebcamCaptureModal from "./SendMessageActions/WebcamCaptureModal.vue";
import SendGuidanceModal from "./SendMessageActions/SendGuidanceModal.vue";
import CreateAnnouncementModal from "./Announcements/CreateAnnouncementModal.vue";
import SendLocationModal from "../views/Incidents/Show/SendLocationModal.vue";
import {mapGetters} from "vuex";

export default {
  props: [
    "myPresenceId",
    "tenantId",
    "orgId",
    "membership",
    "type",
    "cannotAddStaffMembers",
    "incidentId"
  ],

  components: {
    SendHelpVideoModal,
    UploadMedia,
    AddTaskListModal,
    AddStaffMembersModal,
    WebcamCaptureModal,
    SendGuidanceModal,
    CreateAnnouncementModal,
    SendLocationModal
  },

  watch: {
    orgId() {
      this.isAnnouncementModalInitialised = false;
    }
  },

  data() {
    return {
      showPopover: false,
      addStaffMembersModalVisible: false,
      addTaskListModalVisible: false,
      sendHelpVideoModalVisible: false,
      showMediaUploader: false,
      showWebcamCaptureModal: false,
      showSendGuidanceModal: false,

      isAnnouncementModalInitialised: false,
      createAnnouncementModalVisible: false,

      sendLocationModalVisible: false
    };
  },

  computed: {
    ...mapGetters("missionControl", {
      guidanceModeEnabled: "guidanceModeEnabled",
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor",
      idsOfOrganisationsICanCreateAnnouncementsFor: "idsOfOrganisationsICanCreateAnnouncementsFor"
    }),

    isChat() {
      return this.type == "chat";
    },

    canCreateAnnouncement() {
      // return this.idsOfOrganisationsIAmAnAdminFor.includes(this.orgId);
      return this.idsOfOrganisationsICanCreateAnnouncementsFor.includes(this.orgId);
    }
  },

  methods: {
    openAddTaskListModal() {
      this.showPopover = false;
      this.addTaskListModalVisible = true;
    },

    closeAddTaskListModal() {
      this.addTaskListModalVisible = false;
    },

    openSendHelpVideoModal() {
      this.showPopover = false;
      this.sendHelpVideoModalVisible = true;
    },

    closeSendHelpVideoModal() {
      this.sendHelpVideoModalVisible = false;
    },

    openAddStaffMembersModal() {
      this.showPopover = false;
      this.addStaffMembersModalVisible = true;
    },

    closeAddStaffMembersModal() {
      this.addStaffMembersModalVisible = false;
    },

    addStaffMembers(memberIds) {
      this.$emit("add-staff-members", memberIds);
    },

    openMediaUploader() {
      this.showMediaUploader = false;
      this.$nextTick(() => {
        this.showMediaUploader = true;
      });
    },

    handleFileUploaded() {
      this.showMediaUploader = false;
      this.showPopover = false;
    },

    addChecklist(checklist) {
      this.$emit("add-checklist", checklist);
    },

    openWebcamCaptureModal() {
      this.showPopover = false;
      this.showWebcamCaptureModal = true;
    },

    closeWebcamCaptureModal() {
      this.showWebcamCaptureModal = false;
    },

    handleWebcamImageUploaded() {
      this.showWebcamCaptureModal = false;
      this.showPopover = false;
    },

    openSendGuidanceModal() {
      this.showPopover = false;
      this.showSendGuidanceModal = true;
    },

    closeSendGuidanceModal() {
      this.showSendGuidanceModal = false;
    },

    openCreateAnnouncementModal() {
      if (!this.isAnnouncementModalInitialised) {
        this.isAnnouncementModalInitialised = true;
      }
      let vm = this;
      this.$nextTick(() => {
        vm.showPopover = false;
        vm.createAnnouncementModalVisible = true;
      });
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },

    openSendLocationModal() {
      this.showPopover = false;

      this.sendLocationModalVisible = false;

      this.$nextTick(() => {
        this.sendLocationModalVisible = true;
      });
    },

    closeSendLocationModal() {
      this.sendLocationModalVisible = false;
    }
  },
};
</script>

<style scoped lang="scss">
.buttons-wrapper {
  display: flex;
  flex-direction: column;

  .ant-btn {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>